// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore';
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDoCcmVu8VhuuYkVY-dI8-SQoCoLElRIP8",
  authDomain: "zephyr-a46a8.firebaseapp.com",
  projectId: "zephyr-a46a8",
  storageBucket: "zephyr-a46a8.appspot.com",
  messagingSenderId: "646103088985",
  appId: "1:646103088985:web:12bdb9d3671c3979109648",
  measurementId: "G-RTNS1YTV8D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth =  getAuth(app);
export const googleProvider = new GoogleAuthProvider();
