export const commonColors = {
  MainColor: "#7BC6CC",
  AccentColor: "#E0E4E5",
  HighlightColor: " #FFFFFF",
  SecondaryAccent: " #A8DADC",
  blackColor: "#000",
  hashBorder: "#e6dddd",
  shadowGaryColor:"#B0AFAF",
  dynamicOrange:"#DF7E48",
  dynamicYellow:"#DFB948",
  dynamicRed:'#EB5151',
  dynamicGreen:"#5BB74C",
  dynamicBlue:"#54b1e4",
  dynamicPurple:"#7A48DF",
  dynemicLightPurple: '#708CFF',
  fadedMainColor: "#e8f5f6",
  dynamicFadedOrange:"#f5ede8",
  dynamicFadedYellow:"#f4f1e9",
  dynamicFadedRed:'#f0e6e6',
  dynamicFadedGreen:"#F2F9F0",
  dynamicFadedBlue:"#e7edf1",
  dynamicFadedPurple:"#ebe8f2",
  dynemicFadedLightPurple: '#eef0fb',
  whiteColor:"#FFFFFF",
  lightGrayColor:"#F7F7F7",
  successColor:"#29A35A",
  lightPurpleColor: "#3E64FF",
  inputBg:"#DBDBDB",
  lightblueColor:"#E2EBF3",
  fadedBlack: "#616161"
};
