import React from "react";
import {
    Stepper,
    Step,
    StepLabel,
  } from "@mui/material";
import { commonColors } from "../../../Assets/colors.ts";

interface stepperDetails {
    steps : any;
    currentstep: any
}

export default function StepperComponent ({steps, currentstep}: stepperDetails) {
    return (
    <Stepper
        sx={{
          "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": { borderColor:commonColors.successColor},
          "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": { borderColor: "green"},
          "& .MuiStepIcon-root.Mui-active ": { color: commonColors.lightPurpleColor,},
          "& .MuiStepLabel-label.Mui-active": {color: commonColors.lightPurpleColor,
            fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"},
          "& .MuiStepIcon-root.Mui-completed": { color: commonColors.successColor},
          "& .MuiStepLabel-label.Mui-completed": {color: commonColors.successColor,
            fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"},
          "& .Mui-disabled .MuiStepIcon-root": { color: "transparent", border:`1px solid ${commonColors.shadowGaryColor}`,borderRadius:"12px"},
          "& .Mui-disabled .MuiStepLabel-label": {color: commonColors.shadowGaryColor,
            fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"},
          "& .Mui-disabled .css-117w1su-MuiStepIcon-text": { fill: commonColors.shadowGaryColor }
      }}
    activeStep={currentstep} alternativeLabel>
      {steps.map((label:string, i:Number) => (
        <Step key={label}>
          <StepLabel>
              {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
    )
}