import React, { Fragment, useEffect, useState,  } from "react";
import { Avatar, Box, Button, Divider, Drawer, List, ListItem, TextField, Typography, useMediaQuery, InputAdornment } from "@mui/material";
import LogoImg from "../../../Assets/Images/image/bird.jpg";
import "./headerBar.css";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { paths } from "../../../values/paths.ts";
import MenuIcon from '@mui/icons-material/Menu';
import Cookies from 'universal-cookie';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { commonColors } from "../../../Assets/colors.ts";
import HelpIcon from '@mui/icons-material/Help';
import SearchField from "../searchField/searchField.tsx";
import { useNavigate } from "react-router";

interface headerDetails {
    openAddCards?: any;
    openDrawer: any;
    handleDrawer: any;
    searchInput?:any;
    clearSearchInput?:any;
    handleSearch?:any;
    commonText?: any;
}

export default function HeaderBar ({commonText, openAddCards, openDrawer, handleDrawer, searchInput, clearSearchInput,handleSearch} : headerDetails){

  // Custom states
  const cookies = new Cookies();
    const maxWidth890px = useMediaQuery('(max-width:890px)')
    const navigateTo = useNavigate()
    const navagtionBtnsList = [
      {
          name: "Profile",
          icon: <PersonOutlineOutlinedIcon/>,
          navigate: paths.cards
      },
      {
          name: "Scan",
          icon: <QrCodeScannerOutlinedIcon/>,
          navigate: paths.scanner
      },
      {
          name: "Your QR",
          icon: <QrCodeIcon/>,
          navigate: paths.qrcode
      },
      {
          name: "Contact",
          icon: <PermContactCalendarOutlinedIcon/>,
          navigate: paths.contact
      },
      {
          name: "Settings",
          icon: <SettingsOutlinedIcon/>,
          navigate: paths.setting
      },

  ]
  // Custom Methods

  const handleLogOut= ()=>{
    cookies.remove('user')
    cookies.remove('token')
    cookies.remove('idToken')
   navigateTo(paths.login)
   window.location.reload()
  }

  // Ui Implamentation
    return(
        <Box className="headerBarBox">
          {/* <div className="headerLogoImg">
            <img src={LogoImg} alt="LogoImg" width={'100%'}/>
          </div> */}
        {!maxWidth890px &&  <div className="headerPannelsBox">
        <Button disableRipple className="drawerBtn" onClick={() => handleDrawer(!openDrawer)}>
            <MenuIcon/>
         </Button>
        {/* {navagtionBtnsList.map((btn, index) => (
            <Button  href={btn.navigate} key={btn.name} disableRipple className={currentPath === btn.navigate ?"selectedPannelBtns":"pannelBtns"}>
              {btn.icon}
              {btn.name}
            </Button>
        ))} */}
          </div>}
      <div className="width_100">
      {!maxWidth890px  &&   <>
        <SearchField
           searchData={searchInput}
           handleSearch={handleSearch}
           commonText={commonText}
           clearSearchInput={clearSearchInput}
          />
      {/* <TextField
        value={searchInput}
        onChange={(e:any) => handleSearch(e)}
        size="small"
        placeholder={`${commonText?.search}...`}
        className='searchBarHeader'
        sx={{
          border: "1px solid", borderRadius: "8px", borderColor: commonColors.lightGrayColor, backgroundColor: commonColors.whiteColor,
          "& .MuiOutlinedInput-root": { "& > fieldset": { border: "none", height: "50px"} },
          input: {
            fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
            // backgroundColor:commonColors.AccentColor,
            "&::placeholder": {
              opacity: 0.5,
              fontWeight: 400,
              fontSize: "14px",
            fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;"
          }
        }}}
        InputProps={{
          startAdornment: <InputAdornment position="start"
          >
            <img className="searchIcon" src={require('../../../Assets/Images/Icons/search.png')} />
          </InputAdornment>,
          endAdornment:  <InputAdornment position="end" className="clickable">
         {searchInput?.length > 0 &&
          <CloseIcon onClick={clearSearchInput} />}
        </InputAdornment>
        }}
      /> */}
            {/* <TextField
            size="small"
            type="search"
            className="searchBarHeader"
            /> */}
          <Button className="headerAddBtn" onClick={openAddCards}>
          <Avatar src={require("../../../Assets/Images/Icons/facebook.png")} alt="" />
          <KeyboardArrowDownIcon />
          {/* <AddOutlinedIcon sx={{ marginRight: "8px" }} />
          Add */}
        </Button> </>  }
         { maxWidth890px && 
         <>
         <div className="headerLogoMobileBox">
          <img src={require('../../../Assets/Images/Icons/logo.png')} alt="logo" className="headerLogoMobile"/>
         </div>
         <Button disableRipple className="drawerBtn" onClick={() => handleDrawer(true)}>
            <MenuIcon fontSize="large"/>
         </Button>
         <Drawer
            open={openDrawer}
            onClose={() => handleDrawer(false)}
            >
              <Box className="headingDrawer">
              <div className="headerLogoImg">
            <img src={require('../../../Assets/Images/Icons/logo.png')} alt="LogoImg" width={'100%'}/>
          </div>
                  <List className="drawerListBox">
          <Divider variant="fullWidth" />
          {navagtionBtnsList.map((btn) => (
            <Fragment key={btn.name}>
             <ListItem>
              <Button href={btn.navigate} key={btn.name} disableRipple className="drawerBtn">
                {btn.icon}
                {btn.name}
              </Button>
            </ListItem>
            <Divider/>
            </Fragment>
          ))}
          </List>
          <Box className="helpLogoutbox">
          <Button disableRipple  className="drawerLogoutBtn" onClick={handleLogOut}>
          <HelpIcon/> 
           {commonText?.help}
            </Button>
          <Button disableRipple  className="drawerLogoutBtn" onClick={handleLogOut}>
            <img src={require(`../../../Assets/Images/Icons/signOut.png`)} alt='Signout' className="logoutBtnImg" />
             {commonText?.logOut}
            </Button>
            </Box>
              </Box>
         </Drawer>
         </>}
          </div>
        </Box>
    )
 }