import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Typography } from '@mui/material'
import React, { useState } from 'react'
import { commonColors } from '../../../Assets/colors.ts'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import KeyboardOptionKeyIcon from '@mui/icons-material/KeyboardOptionKey';
import './customCards.css'
import { RWebShare } from "react-web-share";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LanguageIcon from '@mui/icons-material/Language';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

interface props {
    searchData?:any;
    showSearchData?:any;
    cards?:any;
    handleEdit?:any;
    commonTexts?:any;
    handleDeleteCard?:any;
    handleOpen: any;
}

function CustomCards({searchData,showSearchData,cards,handleEdit,commonTexts, handleDeleteCard, handleOpen}:props) {
    const showData = searchData ? showSearchData :cards
    // Custome States
    const [viewMore, setviewMore] = useState(false)
    const [selectedCard, setSelectedCard] = useState<any>()
    // const [reload, setReload] = useState(false)

    // Custome Methods
  const handleViewMore = (index: number) => {
    // If selectedCard has an value already and it's match with current index  then this condition will run and selectedCard will be null.
    if (index === selectedCard) {
      setSelectedCard(null)
    } else {
    // If selected card is null or don't have any value, then current index will pass in selectedcard and that card will show more details about that card. 
      setSelectedCard(index)
    }
    }

    const handleShare = (id:any,item:any) => {
      navigator.clipboard.writeText(`http://localhost:3001/card/${id}`);
      console.log("asd---",`http://localhost:3001/card/${id}`);
      // cookies.set('cardData',item)
    }
    // UI Implamnetation
  return (
    <div>
        <Box className="displayCardBox">
        {showData.length < 1 && 
        <div className='emptycardBox'>
          <img src={require('../../../Assets/Images/image/emptyCardsImg.png')} alt="" className='emptycardImg' />
         <Typography className='emptycardText'>{commonTexts?.youDontHaveBusinessCard}</Typography>
         <Typography className='emptycardText2'>{commonTexts?.MakeyourBusinessCard}</Typography>
         <Button className="headerAddBtn1" onClick={handleOpen}>
          <AddOutlinedIcon sx={{ marginRight: "8px" }} />
          {commonTexts?.add}
        </Button>
        </div>
         }
         <Box className="allNewCardBox">
        {showData.map((it:any, i:number) => (
          // To Delete card use this onClick Method any where in card onClick={(e) => handleDeleteCard(e, true, it.cardId)}.
     <Box className="newCardBox" key={it.cardId}>
        {/* static image path because api's path is not working . */}
      <img className='newCardImg' src={require('../../../Assets/Images/image/demo1.jpg')} alt="image" />
      <div className='newCardDetailsDiv'>
        <div className='newCardtxtsDiv'>
        <Typography className='newcardNameTxt'>
          {/* static Text until required keys are not set to the Api response. */}
          Jhon Doe 
        </Typography>
        <Typography className='newcardRoleTxt'>
          {/* static Text until required keys are not set to the Api response. */}
          Manager • Tesla
        </Typography>
        </div>
        {/* static logo path because api's path is not working . */}
        <img className='newCardLogo' src={require('../../../Assets/Images/Icons/logo.png')} alt="logo" />
      </div>
     </Box>
        ))}
        </Box>
      </Box>
    </div>
  )
}

export default CustomCards