import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { commonColors } from '../../Assets/colors.ts'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import './contactCards.css'

interface props {
  contactCard?:any
  handleContactEdit?:any
  showSearchData?:any
  searchData?:any
}

function ContactCards({contactCard,handleContactEdit ,showSearchData,searchData}:props) {

  const showData = searchData ? showSearchData :contactCard


  console.log("asd---",showData);
  
  return (
    <Box className='c-cardMainBox'>
      {
        showData?.map((item:any, index:any) => (
          <Box className='c-MainCard' >
            <a onClick={() => handleContactEdit(item,index)}><EditRoundedIcon /></a>
          <div
          className='c-cardImgBox'
          style={{
            backgroundColor:item.theme === 6 ? commonColors.AccentColor:
            item.theme === 5 ? commonColors.dynamicPurple: 
            item.theme === 4? commonColors.dynamicBlue: 
            item.theme === 3 ? commonColors.dynamicGreen: 
            item.theme === 2 ? commonColors.dynamicOrange: commonColors.MainColor,
          }}>
            <img src={item.profileImg ? item.profileImg :'https://static.vecteezy.com/system/resources/previews/020/213/738/non_2x/add-profile-picture-icon-upload-photo-of-social-media-user-vector.jpg'} 
            className='c-cardImg'
            />
          </div>
          
          <Accordion
          sx={{border:"none" , padding:"0px",
          '&:before': {
            display: 'none',
          }}}
          variant='outlined'
          >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{padding:"0px",border:"none" }}
        ><div>
        <Typography>{item.prefix  }{item.firstName} {item.middleName} {item.lastName}</Typography>
      </div>
        </AccordionSummary>
        <div className='c-cardDesp'>
        <Typography>{item.suffix}</Typography>
        <Typography>{item.accreditations}</Typography>
        <Typography>{item.prefferredName}</Typography>
        <Typography>{item.maidenName}</Typography>
        <Typography>{item.pronouse}</Typography>
        <Divider />
        <Typography>{item.title}</Typography>
        <Typography>{item.department}</Typography>
        <Typography>{item.company}</Typography>
        <Typography>{item.headline}</Typography>
        <Divider />
        {
          item.moreInfo.map((item:any) => (
            <div className='c-cardAddInfo' >
            <item.logo />
            <Typography>{item.label === 'Phone' ? <>{item.subInput} {item.firstInput}</> : <>{item.firstInput},{item.subInput}</>}</Typography>
            </div>
          ))
        }
        </div>
      </Accordion>
        </Box>
        ))
      }
    </Box>
  )
}

export default ContactCards