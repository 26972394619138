import React from "react";
import {
  Box,
  Typography,
  TextField,
  Autocomplete,
  Divider,
  useMediaQuery
} from "@mui/material";
// import "./addCardLayout.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import countryCodes from "../addCardLayout/countries.json";
import { CommonColors } from "@mui/material";
import { commonColors } from "../../../Assets/colors.ts";

interface SecondStepLayouttDetails {
  showAlert: any;
  cardData: any;
  commonTexts: any;
  handleCardLayout: any;
  validateEmail: any;
  handleCountrycode: any;
  countryCode: any;
  windowHeight:any
}


export default function SecondStepLayout({
  showAlert,
  cardData,
  commonTexts,
  handleCardLayout,
  validateEmail,
  handleCountrycode,
  countryCode,
  windowHeight,
}: SecondStepLayouttDetails) {
  
  const tabletHeight = useMediaQuery('(max-height:550px)')

  return (
    <Box className="yourDetailsMainbox">
      <div className="detailsFilleBox">
        <Box className="detailsLabelInputBox">
          <Typography className="headingFont">
            {commonTexts?.fullName}
          </Typography>
          {showAlert && cardData.fullName?.length == 0 && (
            <Typography className="alertText">
              {commonTexts?.enterFullName}
            </Typography>
          )}
          <TextField
            placeholder={commonTexts?.yourFullName}
            size="small"
            value={cardData.fullName}
            onChange={(e) => handleCardLayout("fullName", e)}
            className="detailsInputField"
            sx={{
              input: {
                fontFamily:
                  "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
            //  backgroundColor: commonColors.inputBg
                },
            }}
          />
        </Box>
        <Box className="detailsLabelInputBox">
          <Typography className="headingFont">
            {commonTexts?.emailAddress}
          </Typography>
          {showAlert && !validateEmail(cardData.emails) && (
            <Typography className="alertText">
              {commonTexts?.enterYourEmail}
            </Typography>
          )}
          <TextField
            placeholder={commonTexts?.yourEmail}
            size="small"
            value={cardData.emails}
            onChange={(e) => handleCardLayout("emails", e)}
            className="detailsInputField"
            sx={{
              input: {
                fontFamily:
                  "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
                  // backgroundColor: commonColors.inputBg
                },
            }}
          />
        </Box>
        <Box className="detailsLabelInputBox">
          <Typography className="headingFont">
            {commonTexts?.phoneNumber}
          </Typography>
          {showAlert && cardData.phoneNumber?.length == 0 && (
            <Typography className="alertText">
              {commonTexts?.enterphonenumber + " "}
            </Typography>
          )}
          <Box className="phoneNumberInputBox">
            <Autocomplete
              onChange={(event: any, newValue: string | null) => {
                handleCountrycode(newValue);
              }}
              className="phoneNumberSelectionBox"
              id="country-select1"
              popupIcon={null}
              options={countryCodes.Code}
              disableClearable
              autoHighlight
              freeSolo
              underlinestyle={{ display: "none" }}
              defaultValue={countryCode}
              componentsProps={{ popper: { style: { width: "fit-content" } } }}
              getOptionLabel={(option) => option.phone}
              isOptionEqualToValue={(option, selected) =>
                selected.phone === "" || option.phone === selected.phone
                  ? true
                  : false
              }
              renderOption={(props, option) => (
                <Box
                  key={option.label}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.phone}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  sx={{
                    "& .MuiInputBase-root": {
                      fontSize: "14px",
                      borderRadius: "0px",
                      padding: "0px 0px 0px 0px",
                      input: {
                        fontFamily:
                          "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
                     
                        },
                      // To hide up/down buttons in number type field.
                      "& .MuiStandardInput-input": {
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            "-webkit-appearance": "none",
                          },
                      },
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    size: "small",
                    disableUnderline: true,
                    startAdornment: (
                      <img
                        className="phoneNumberSelectionImg"
                        src={`https://flagcdn.com/w20/${countryCode?.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${countryCode?.code.toLowerCase()}.png 2x`}
                        alt=""
                      />
                    ),
                  }}
                />
              )}
            />
            <Divider orientation="vertical" className="phoneNumberDivider" />
            <TextField
              type="number"
              value={cardData.phoneNumber}
              onChange={(e) => handleCardLayout("phoneNumber", e)}
              size="small"
              autoComplete="off"
              className="phoneNumberfilled"
              placeholder={commonTexts?.enterphonenumber}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": {
                    border: "none",
                    borderColor: "#ffff !important",
                  },
                },
                input: {
                  fontFamily:
                    "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
                },
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none",
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputProps={{ className: "auto-inputBox" }}
            />
          </Box>
        </Box>
        {/* <Box className="detailsLabelInputBox">
            <Typography className="headingFont">
              {commonTexts?.phoneNumber}
            </Typography>
            {showAlert && cardData.phoneNumber?.length == 0 && (
              <Typography className="alertText">
                {commonTexts?.enterphonenumber}{" "}
              </Typography>
            )}
            <TextField
              size="small"
              value={cardData.phoneNumber}
              onChange={(e) => handleCardLayout("phoneNumber", e)}
              className="detailsInputField"
              sx={{
                input: {
                  fontFamily:
                    "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
                },
              }}
            />
          </Box> */}
        <Box className="detailsLabelInputBox">
          <Typography className="headingFont">
            {commonTexts?.companyName}
          </Typography>
          {showAlert && cardData.company?.length == 0 && (
            <Typography className="alertText">
              {commonTexts?.enterCompany}
            </Typography>
          )}
          <TextField
            placeholder={commonTexts?.yourCompany}
            size="small"
            value={cardData.company}
            onChange={(e) => handleCardLayout("company", e)}
            className="detailsInputField"
            sx={{
              input: {
                fontFamily:
                  "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
              },
            }}
          />
        </Box>
        <Box className="detailsLabelInputBox">
          <Typography className="headingFont">
            {commonTexts?.rolePosition}
          </Typography>
          {showAlert && cardData.role?.length == 0 && (
            <Typography className="alertText">
              {commonTexts?.enterPosition}
            </Typography>
          )}
          <TextField
            size="small"
            placeholder={commonTexts?.yourPosition}
            value={cardData.role}
            onChange={(e) => handleCardLayout("role", e)}
            className="detailsInputField"
            sx={{
              input: {
                fontFamily:
                  "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
              },
            }}
          />
        </Box>
      </div>
    </Box>
  );
}
