import { Avatar, Box, Button, Dialog, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import './setting.css'
import { validateEmail } from '../../utils/commonRegex.ts'

interface props {
  user?:any
  texts?:any
}


function Setting({user,texts}:props) {
  const [profileDialog , setProfileDialog] = useState(false)
  const [accountDialog , setAccountDialog] = useState(false)
  const [resendDialog , setResendDialog] = useState(false)
  const [resetDialog, setResetDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [profileData,setProfileData] = useState({
    avatar:user?.photoURL,
    name:user?.displayName
  })
  const [accountData, setAccountData] = useState({
    email:user?.email ,
    oldPassword:'',
    newPassWord:'',
    confirmPassword:'',
  })
  const [showAlert,setShowAlert]= useState(false)
  const [showError,setShowError]= useState(false)
  const [sentEmail, setSentEmail] = useState(false)
  const handleProfileData = (keyName: any, e: any) => {
    if(keyName === 'name'){
      setProfileData({ ...profileData, [keyName]:  e.target.value });
    } else {
    setProfileData({ ...profileData, [keyName]:  URL.createObjectURL(e.target.files[0]) });
    }
  };

  const handleAccountData = (keyName:any , e:any) => {
    setAccountData({...accountData, [keyName]: e.target.value})
  }
  
  const handleSubmitData = () => {
      if(profileData?.name.length){
        setProfileDialog(false)
        setShowAlert(false)
      } else {
        setShowAlert(true)
      }
  }

  const handleAccoutSubmitData = () =>{
    if(accountDialog) {
      if(accountData?.email?.length){
        if(validateEmail(accountData?.email)){
          setAccountDialog(false)
          setShowError(false)
        } else{
          setShowError(true)
        }
        setShowAlert(false)
      } else {
        setShowAlert(true)
      }
    } else if (resendDialog) {
      setResendDialog(false)
      setSentEmail(false)
    } else if (resetDialog) {
      setResetDialog(false)
    }else if (deleteDialog) {
      setDeleteDialog(false)
    }
  
  }

  const handleSentEmail = () => {
    setSentEmail(true)
  }
  return (
    <Box className='settingMainBox' >
      <Typography className='settingHeading'>{texts.commonText.setting}</Typography>
      <Box className='contentBox'>
        <div className='settingProfileBox'>
          <Typography>{texts.commonText.profile}</Typography>
          <div className='profileDetails' onClick={() => setProfileDialog(true)}>
            <Avatar src={profileData?.avatar}/>
            <span>
            <Typography>{profileData?.name}</Typography>
            <Typography>free</Typography>
            </span>
          </div>
          <Dialog
          open={profileDialog}
          onClose={() => setProfileDialog(false)}
          >
            <Box className='editProfileDialog' >
              <Typography>{texts.commonText.editProfile}</Typography>
              <span className='editAvatar'>
                <input hidden id='avatar' type="file" onChange={(e) => handleProfileData('avatar',e)} />
                <label htmlFor='avatar'>
                <Avatar src={profileData?.avatar}/></label>
                <Typography>{texts.commonText.upload}</Typography>
              </span>
              <span className='editName'>
                <Typography>{texts.commonText.name}</Typography>
                <TextField className='editNameInput' variant="filled" size="small" 
                value={profileData.name}
                onChange={(e) => handleProfileData('name',e)}
                />
                 {showAlert && profileData.name.length == 0 && (
                      <Typography className="alertText">{texts.commonText.enterName}</Typography>
                    )}
              </span>
              <span className='btnBox'>
                <a onClick={() => setProfileDialog(false)}>{texts.commonText.cancel}</a>
                <a onClick={() => handleSubmitData()}>{texts.commonText.save}</a>
              </span>
            </Box>
          </Dialog>
        </div>

        <div className='settingAccountBox'>
          <Typography>Account</Typography>
          <div className='profileDetails' onClick={() => setAccountDialog(true)}>
            <span>
            <Typography>{texts.commonText.changeEmail}</Typography>
            <Typography>{accountData?.email}</Typography>
            </span>
          </div>
          <div className='profileDetails' onClick={() => setResendDialog(true)}>
            <span>
            <Typography>{texts.commonText.resendEmail}</Typography>
            </span>
          </div>
          <div className='profileDetails' onClick={() => setResetDialog(true)}>
            <span>
            <Typography>{texts.commonText.resetPassword}</Typography>
             </span>
          </div>
          <div className='profileDetails' onClick={() => setDeleteDialog(true)}>
            <span>
            <Typography>{texts.commonText.deleteAccount}</Typography>
            </span>
          </div>
          <Dialog
          open={accountDialog}
          onClose={() => setAccountDialog(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",  // Set your width here
              },
            },
          }}
          >
            <Box className='editProfileDialog' >
              <Typography>{texts.commonText.updateEmail}</Typography>
              <span className='editName'>
                <Typography>{texts.commonText.email}</Typography>
                <TextField className='editNameInput' variant="filled" size="small" 
                placeholder='example123@gmail.com'
                type='email'
                value={accountData?.email}
                onChange={(e) => handleAccountData('email',e)}
                />
                  {showAlert && accountData?.email?.length == 0 && (
                    <Typography className="alertText">{texts.commonText.enterEmail}</Typography>
                  )}
                  { showError && (
                    <Typography className="alertText"> {texts.commonText.emailFormat} </Typography>
                  )}
              </span>
              <span className='btnBox'>
                <a onClick={() => setAccountDialog(false)}>{texts.commonText.cancel}</a>
                <a type='submit' onClick={() => handleAccoutSubmitData()}>{texts.commonText.accept}</a>
              </span>
            </Box>
          </Dialog>
          <Dialog
          open={resendDialog}
          onClose={() => setResendDialog(false)}
           sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "500px",  // Set your width here
        },
      },
    }}
          >
            <Box className='editProfileDialog' >
              {
                sentEmail ? 
                <>
                 <Typography>{texts.commonText.mailSent}</Typography>
                 <a type='submit' onClick={() => handleAccoutSubmitData()}>Ok</a>
                </>:
                <>
                 <Typography>{texts.commonText.emailbesend}</Typography>
              {accountData?.email}
              <span className='btnBox'>
                <a onClick={() => setResendDialog(false)}>{texts.commonText.cancel}</a>
                <a type='submit' onClick={() => handleSentEmail()}>{texts.commonText.accept}</a>
              </span>
                </>
              }
             
            </Box>
          </Dialog>
          <Dialog
          open={resetDialog}
          onClose={() => setResetDialog(false)}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px",  // Set your width here
                },
              },
            }}
          >
            <Box className='editProfileDialog' >
            <Typography>{texts.commonText.resetPassword}</Typography>
              <span className='editName'>
                <Typography>{texts.commonText.oldPassword}</Typography>
                <TextField className='editNameInput' variant="filled" size="small" 
                placeholder='Old Password'
                value={accountData?.oldPassword}
                onChange={(e) => handleAccountData('oldPassword',e)}
                />
              </span>
              <span className='editName'>
                <Typography>{texts.commonText.newPassword}</Typography>
                <TextField className='editNameInput' variant="filled" size="small" 
                placeholder='New Password'
                value={accountData?.newPassWord}
                onChange={(e) => handleAccountData('newPassWord',e)}
                />
              </span>
              <span className='editName'>
                <Typography>{texts.commonText.confirmPassword}</Typography>
                <TextField className='editNameInput' variant="filled" size="small" 
                placeholder='Confirm Password'
                value={accountData?.confirmPassword}
                onChange={(e) => handleAccountData('confirmPassword',e)}
                />
              </span>
              <span className='btnBox'>
                <a onClick={() => setAccountDialog(false)}>{texts.commonText.cancel}</a>
                <a type='submit' onClick={() => handleAccoutSubmitData()}>{texts.commonText.accept}</a>
              </span>
            </Box>
          </Dialog>
          <Dialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "500px",  // Set your width here
                },
              },
            }}
          >
            <Box className='editProfileDialog' >
            <Typography>{texts.commonText.areyouSure}</Typography>
              <span className='btnBox'>
                <a onClick={() => setDeleteDialog(false)}>{texts.commonText.cancel}</a>
                <a type='submit' onClick={() => handleAccoutSubmitData()}>{texts.commonText.accept}</a>
              </span>
            </Box>
          </Dialog>
        </div>
      </Box>
    </Box>
  )
}

export default Setting