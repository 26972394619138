import { Box, Button, Drawer, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { commonColors } from '../../../Assets/colors.ts'
import './addContact.css'
import WebRoundedIcon from "@mui/icons-material/WebRounded";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
interface props{
    open?:any
    handleClose?:any
    handleSubmitData?:any
    handleContactData?:any
    handleAddMoreInfo?:any
    contactData?:any
    showAlert?:any
    handleRemove?:any
    filterData?:any
    handleAddMoreInput?:any
    showSubAlert?:any
    addMoreInfo?:any
    error?:any
    commonText?:any
}

function AddContact({open,handleClose,handleSubmitData,handleContactData,handleAddMoreInfo,contactData,showAlert,handleRemove,filterData,handleAddMoreInput,showSubAlert,addMoreInfo,error,commonText}:props) {
    const color = [{color:1},{color:2},{color:3},{color:4},{color:5},{color:6} ]
    const addMore = [
      {
        id:1,
        label:'Phone',
        logo:WebRoundedIcon
      },
      {
        id:2,
        label:'Email',
        logo:WebRoundedIcon
      },
      {
        id:4,
        label:'Address',
        logo:WebRoundedIcon
      },
      {
        id:5,
        label:'Website',
        logo:WebRoundedIcon
      },
      {
        id:6,
        label:'Link',
        logo:WebRoundedIcon
      },
      {
        id:7,
        label:'PDF',
        logo:WebRoundedIcon
      },
      {
        id:8,
        label:'X.com',
        logo:WebRoundedIcon
      },
      {
        id:9,
        label:'Instagram',
        logo:WebRoundedIcon
      },
      {
        id:10,
        label:'Linkedin',
        logo:WebRoundedIcon
      },
      {
        id:11,
        label:'Facebook',
        logo:WebRoundedIcon
      },
      {
        id:12,
        label:'Youtube',
        logo:WebRoundedIcon
      },
      {
        id:13,
        label:'WhatsApp',
        logo:WebRoundedIcon
      },
      {
        id:14,
        label:'Snapchat',
        logo:WebRoundedIcon
      },
      {
        id:15,
        label:'TikTok',
        logo:WebRoundedIcon
      },
      {
        id:16,
        label:'Github',
        logo:WebRoundedIcon
      },
      {
        id:17,
        label:'Yeip',
        logo:WebRoundedIcon
      },
      {
        id:18,
        label:'Venmo',
        logo:WebRoundedIcon
      },
      {
        id:19,
        label:'PayPal',
        logo:WebRoundedIcon
      },
      {
        id:20,
        label:'CashApp',
        logo:WebRoundedIcon
      },
      
      {
        id:21,
        label:'Calendly',
        logo:WebRoundedIcon
      },
      
      {
        id:22,
        label:'Discord',
        logo:WebRoundedIcon
      },
      
      {
        id:23,
        label:'Twitch',
        logo:WebRoundedIcon
      },
      
      {
        id:24,
        label:'Telegram',
        logo:WebRoundedIcon
      },
      {
        id:25,
        label:'Zelte',
        logo:WebRoundedIcon
      },
      {
        id:26,
        label:'Skype',
        logo:WebRoundedIcon
      },
      {
        id:27,
        label:'Wechat',
        logo:WebRoundedIcon
      },
      {
        id:28,
        label:'Signal',
        logo:WebRoundedIcon
      },
      {
        id:29,
        label:'Nintendo Switch',
        logo:WebRoundedIcon
      },
      {
        id:30,
        label:'PSN',
        logo:WebRoundedIcon
      },
      {
        id:31,
        label:'Xbox Live',
        logo:WebRoundedIcon
      },
      {
        id:32,
        label:'Xing',
        logo:WebRoundedIcon
      },
      {
        id:33,
        label:'Dribbble',
        logo:WebRoundedIcon
      },
      {
        id:34,
        label:'Behance',
        logo:WebRoundedIcon
      },
      {
        id:35,
        label:'Pinterest',
        logo:WebRoundedIcon
      },
      {
        id:36,
        label:'Patreon',
        logo:WebRoundedIcon
      },
      {
        id:37,
        label:'Vimeo',
        logo:WebRoundedIcon
      },
      {
        id:38,
        label:'Line',
        logo:WebRoundedIcon
      },
      {
        id:39,
        label:'Date',
        logo:WebRoundedIcon
      },
      {
        id:40,
        label:'Note',
        logo:WebRoundedIcon
      },
      {
        id:41,
        label:'Apple Music',
        logo:WebRoundedIcon
      }, 
    ]
  return (
    <Drawer
    anchor="right"
    open={open}
    onClose={handleClose}
    ModalProps={{
      slotProps: { backdrop: { invisible: true } },
    }}
    PaperProps={{ sx: { width: "100%", maxWidth: "380px" } }}
    >
        <Box>
            <div
            className='contact-drawerImgBox'
            style={{
              backgroundColor:contactData.theme === 6 ? commonColors.AccentColor:
              contactData.theme === 5 ? commonColors.dynamicPurple: contactData.theme === 4? commonColors.dynamicBlue: contactData.theme === 3 ? commonColors.dynamicGreen: contactData.theme === 2 ? commonColors.dynamicOrange: commonColors.MainColor, 
             }}>
              <input id='addImg' hidden type='file' onChange={(e:any) => handleContactData('profileImg',e)} />
              <label htmlFor='addImg'>
                <img className='contact-drawerImg'
                src={contactData.profileImg ? contactData.profileImg :'https://static.vecteezy.com/system/resources/previews/020/213/738/non_2x/add-profile-picture-icon-upload-photo-of-social-media-user-vector.jpg'} />
              </label>
            </div>
            {showAlert &&  contactData.profileImg.length == 0   && (
                  <Typography className="alertText">{commonText.commonText.enterProfileImage}</Typography>)}
            <span className="contact-colorBox" >
                    {color.map((it:any, index:any) => (
                      <span 
                      className="colorSpan"
                      key={index}
                      >
                        <div
                          onClick={() => handleContactData('theme',it.color)}
                          className="customColors"
                          style={{
                            backgroundColor:it.color === 6 ? commonColors.AccentColor:
                            it.color === 5 ? commonColors.dynamicPurple: it.color === 4? commonColors.dynamicBlue: it.color === 3 ? commonColors.dynamicGreen: it.color === 2 ? commonColors.dynamicOrange: commonColors.MainColor,
                          }}
                        ></div>
                      </span>
                    ))}
            </span>
            
            <div className='contact-subBox' >
              <>
              <Typography>{commonText.commonText.fullName}</Typography>
                <TextField 
                size='small'
                className="fieldsInput"
                label="Prefix"
                variant='standard'
                onChange={(e:any) => handleContactData('prefix',e)}
                value={contactData.prefix}
                />
                {showAlert &&  contactData.prefix.length == 0 && (
                  <Typography className="alertText">{commonText.commonText.enterprifix}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="First Name"
                variant='standard'
                onChange={(e:any) => handleContactData('firstName',e)}
                value={contactData.firstName}
                />
                
                {showAlert && contactData.firstName.length == 0 &&  (
                  <Typography className="alertText">{commonText.commonText.enterFirsName}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Middle Name"
                variant='standard'
                onChange={(e:any) => handleContactData('middleName',e)}
                value={contactData.middleName}
                />
                {showAlert && contactData.middleName.length == 0 && (
                  <Typography className="alertText">{commonText.commonText.enterMiddleName}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Last Name"
                variant='standard'
                onChange={(e:any) => handleContactData('lastName',e)}
                value={contactData.lastName}
                />
                {showAlert && contactData.lastName.length == 0 && (
                  <Typography className="alertText">{commonText.commonText.enterlastName}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Suffix"
                variant='standard'
                onChange={(e:any) => handleContactData('suffix',e)}
                value={contactData.suffix}
                />
                {showAlert && contactData.suffix.length == 0 && (
                  <Typography className="alertText">{commonText.commonText.enterSuffix}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Accreditations"
                variant='standard'
                onChange={(e:any) => handleContactData('accreditations',e)}
                value={contactData.accreditations}
                />
                {showAlert &&  contactData.accreditations.length == 0 &&(
                  <Typography className="alertText">{commonText.commonText.enterAccreditation}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Preferred Name"
                variant='standard'
                onChange={(e:any) => handleContactData('prefferredName',e)}
                value={contactData.prefferredName}
                />
                {showAlert &&  contactData.prefferredName.length == 0  &&(
                  <Typography className="alertText">{commonText.commonText.enterPreFerred}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Maiden Name"
                variant='standard'
                onChange={(e:any) => handleContactData('maidenName',e)}
                value={contactData.maidenName}
                />
                {showAlert && contactData.maidenName.length == 0  && (
                  <Typography className="alertText">{commonText.commonText.enterMaiden}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Pronouns"
                variant='standard'
                onChange={(e:any) => handleContactData('pronouse',e)}
                value={contactData.pronouse}
                />
                {showAlert && contactData.pronouse.length == 0  && (
                  <Typography className="alertText">{commonText.commonText.enterPronouns}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Title"
                variant='standard'
                onChange={(e:any) => handleContactData('title',e)}
                value={contactData.title}
                />
                {showAlert && contactData.title.length == 0  && (
                  <Typography className="alertText">{commonText.commonText.enterTitle}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Department"
                variant='standard'
                onChange={(e:any) => handleContactData('department',e)}
                value={contactData.department}
                />
                {showAlert &&  contactData.department.length == 0  && (
                  <Typography className="alertText">{commonText.commonText.enterDepartment}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Company"
                variant='standard'
                onChange={(e:any) => handleContactData('company',e)}
                value={contactData.company}
                />
                {showAlert && contactData.company.length == 0  && (
                  <Typography className="alertText">{commonText.commonText.enterCompany}</Typography>)}
                <TextField 
                size='small'
                className="fieldsInput"
                label="Headline"
                variant='standard'
                onChange={(e:any) => handleContactData('headline',e)}
                value={contactData.headline}
                />
                {showAlert && contactData.headline.length == 0  &&  (
                  <Typography className="alertText">{commonText.commonText.enterHeadline}</Typography>)}
              </>
              <div className='contact-moreInfo' >
              {
                filterData?.map((item:any,i:any) => (
                  <div className='cantact-moreOpt' key={i}>
                    <span style={{display:"flex",justifyContent:"space-between"}}>
                    <Typography>{item.label}</Typography>
                    <a onClick={() => handleRemove(item)}><CloseRoundedIcon /></a>
                    </span>
                   <TextField 
                size='small'
                className="fieldsInput"
                placeholder={item.label === 'Phone' ? '1122334455 or 2233445566' : item.label === 'Email' ? 'example123@gmail.com' :"https://www.example.com/ or www.example.com"}
                variant='standard'
                value={item.firstInput}
                onChange={(e:any) => handleAddMoreInput('mainInput',e,i)}
                />
                 {showSubAlert && item.firstInput.length == 0 &&  (
                  <Typography className="alertText">{commonText.commonText.fillinformation}</Typography>)}
                   {item?.verfiyUrl === false && addMoreInfo.mainInput.length > 0 && !error && (
                      <Typography className="alertText">
                      {
                        item.label === 'Phone' ? <>{commonText.commonText.invalidPhoneFormat}</> : 
                        item.label === 'Email' ? <>{commonText.commonText.invalidEmailFormat}</> : <>{commonText.commonText.invalidURLFormat}</>
                      }     
                    </Typography>
                  )}
                <TextField 
                size='small'
                className="fieldsInput"
                label={item.label === 'Phone' ? '+91 or +1' :"add extention"}
                variant='standard'
                value={item.subInput}
                onChange={(e:any) => handleAddMoreInput('subInput',e,i)}
                />
                 {showSubAlert && item.subInput.length == 0 &&  (
                  <Typography className="alertText">{commonText.commonText.fillinformation}</Typography>)}
                  </div>
                ))
              }
              </div>
              <div className='contact-btnBox' >
                <Button className="submitBtn">{commonText.commonText.cancel}</Button>
                <Button className="submitBtn" onClick={() => handleSubmitData()}>{commonText.commonText.save}</Button>
              </div>

              <div className='contact-addInfoOptBox' >
              <Typography>{commonText.commonText.addinfomation}</Typography>
              <div className='contact-addInfoSubBox'>
                {
                  addMore.map((item) => (
                    <div
                    onClick={() => { filterData.some((items: any) => items.label === item.label) === false ?  handleAddMoreInfo(item) : handleRemove(item)}}
                    key={item.id} 
                    className={filterData.some((items: any) => items.label === item.label) === false ?  'contant-addOpt' : 'contant-addOpt2'}
                    >
                    <item.logo/>
                    <Typography style={{textAlign:"center"}}>{item.label}</Typography>
                    </div>
                  ))
                }
              </div>
              </div>
            </div>
        </Box>
    </Drawer>
  )
}

export default AddContact