import { Box } from '@mui/material'
import React from 'react'
import LoginForm from '../../Components/loginComponents/loginForm.tsx'
import "../dashBoard/dashBoardPage.css";
import texts from "../../Assets/CommonTexts/texts.json"

interface props {
  setIsLogedIn?:any
}

function LoginPage({setIsLogedIn}:props) {
  return (
    <Box className="mainLoginBox">
      <LoginForm commonTexts={texts.commonText} setIsLogedIn={setIsLogedIn}/>
    </Box>
  )
}

export default LoginPage