import { Box, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import SideBar from '../../Components/commonComponents/sideBar/sideBar.tsx'
import Setting from '../../Components/settingComponents/setting.tsx'
import Cookies from 'universal-cookie'
import texts from '../../Assets/CommonTexts/texts.json'
import HeaderBar from '../../Components/commonComponents/headerBar/headerBar.tsx'

function SettingPage() {

const cookies = new Cookies();
const user = cookies.get('user')
const [openDrawer, setOpenDrawer] = useState(false)
const maxWidth890px = useMediaQuery('(max-width:890px)')
const [searchData, setSearchData] = useState('')
const [addNewCard, setAddNewCard] = useState(false);

const handleOpen = () => {
  return setAddNewCard(true);
};

const handleDrawer = (value: any) => {
  setOpenDrawer(value)
}

  return (
    <Box className="mainLoginBox">
      {!maxWidth890px && <SideBar commonTexts={texts.commonText}  openDrawer={openDrawer}/>}
    {/* <HeaderBar /> */}
    <Box className='layout' >
    <HeaderBar openAddCards={handleOpen} openDrawer={openDrawer} handleDrawer={handleDrawer}
              searchInput={searchData}
              commonText={texts.commonText}
              />
    <Setting user={user} texts={texts}/>
    </Box>
    </Box>
  )
}

export default SettingPage