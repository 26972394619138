import React from "react";
import { Box, Typography, Button } from "@mui/material";
import "./whiteBox.css"

interface cardDetails {
    title?: String
    titleColor?: String
    children?: React.ReactElement | React.ReactElement[]
    className?: any
    onClick?: any
    btnTitle?: any
    type?:any
}

export default function WhiteBox({ title, children, className, onClick, btnTitle, titleColor,type }: cardDetails) {
    return (
        <Box className="whiteBox">
            <Typography className={titleColor == 'main' ? 'whiteBoxTitleMain' : 'whiteBoxTitleBlack'}>
                {title}
            </Typography>
            {children}
            <Button className={className} onClick={onClick} type={type}>
                <Typography className="whiteBoxBtnTxt">{btnTitle}</Typography>
            </Button>
        </Box >
    )
}