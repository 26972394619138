import React from "react";
import { Avatar, Box, Button, Divider, Drawer, List, ListItem, TextField, Typography, useMediaQuery, InputAdornment } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { commonColors } from "../../../Assets/colors.ts";

interface searchDetails {
    searchData?: any
    handleSearch?: any
    commonText: any
    clearSearchInput?: any
}

export default function SearchField ({searchData, handleSearch, commonText, clearSearchInput}: searchDetails) {
return (
    <TextField
    value={searchData}
    onChange={(e:any) => handleSearch(e)}
    size="small"
    placeholder={`${commonText?.search}...`}
    className='mobilesearchBarHeader'
    sx={{
     borderRadius: "4px", borderColor: commonColors.lightGrayColor, backgroundColor: commonColors.whiteColor,
      "& .MuiOutlinedInput-root": { "& > fieldset": { border: "none", height: "50px"} },
      input: {
        fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
        // backgroundColor:commonColors.AccentColor,
        "&::placeholder": {
          opacity: 0.5,
          fontWeight: 400,
          fontSize: "14px",
        fontFamily:"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;"
      }
    }}}
    InputProps={{
      startAdornment: <InputAdornment position="start"
      >
        <img className="searchIcon" src={require('../../../Assets/Images/Icons/search.png')} />
      </InputAdornment>,
      endAdornment:  <InputAdornment position="end" className="clickable">
     {searchData?.length > 0 &&
      <CloseIcon onClick={clearSearchInput} />}
    </InputAdornment>
    }}
  />
)
}