import React, { useEffect, useState } from 'react'
import SideBar from '../../Components/commonComponents/sideBar/sideBar.tsx'
import { Box, useMediaQuery } from '@mui/material'
import AddSearchBar from '../../Components/commonComponents/addSearchBar/addSearchBar.tsx'
import AddContact from '../../Components/contactComponents/addContactDrawer/addContact.tsx'
import { OnlyNumbers, validateEmail, validateUrl } from '../../utils/commonRegex.ts'
import texts from '../../Assets/CommonTexts/texts.json'
import ContactCards from '../../Components/contactComponents/contactCards.tsx'
import HeaderBar from '../../Components/commonComponents/headerBar/headerBar.tsx'

function ContactPage() {
  const [open, setOpen] = useState(false)
  const [contactCard, setContactCard] = useState<any>([])
  const [contactData, setContactdata] = useState({
    profileImg:'',
    theme:1,
    prefix:'',
    firstName:'',
    middleName:'',
    lastName:'',
    suffix:'',
    accreditations:'',
    prefferredName:'',
    maidenName:'',
    pronouse:'',
    title:'',
    department:'',
    company:'',
    headline:'',
    moreInfo:[] as any
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showSubAlert, setShowSubAlert] = useState(false)
  const [edit, setEdit] = useState(false)
  const [selectCard, setSelectCard] = useState<any>()
  const [error, setError] = useState(false);
  const [searchData, setSearchData] = useState('')
  const [showSearchData, sewtShowSearchData] = useState(contactCard)
  let check = [] as any
  const [openDrawer, setOpenDrawer] = useState(false)
  const maxWidth890px = useMediaQuery('(max-width:890px)')
  const [addNewCard, setAddNewCard] = useState(false);

  const handleDrawerOpen = () => {
      return setAddNewCard(true);
    };
    
  const handleDrawer = (value: any) => {
      setOpenDrawer(value)
      }
  const [addMoreInfo, setAddMoreInfo] = useState({
    mainInput:'',
    subInput : ''
  })

  const handleContactData = (keyName:any, e:any) => {
    if (keyName === 'profileImg'){
    setContactdata({...contactData, [keyName] : URL.createObjectURL(e.target.files[0])})
    } else if(keyName === 'theme') {
      setContactdata({...contactData, [keyName] : e})
    } else {
      setContactdata({...contactData, [keyName] : e.target.value})
    }
  }

  const filterData = contactData.moreInfo.filter(
    (item: any, i: any) =>
      i === contactData.moreInfo.findIndex((items: any) => items.label === item.label)
  );
 
  const handleAddMoreInfo = (e:any) => {
    setIsLoading(!isLoading)
    const data = {
      id:e.id,
      logo:e.logo,
      label:e.label,
      firstInput:'',
      subInput:'',
      verfiyUrl:false
    }
    contactData.moreInfo.push(data)
  }

  const handleClose = () => {
    setOpen(false)
    check = []
    setContactdata({...contactData,
      profileImg:'',
      theme:1,
      prefix:'',
      firstName:'',
      middleName:'',
      lastName:'',
      suffix:'',
      accreditations:'',
      prefferredName:'',
      maidenName:'',
      pronouse:'',
      title:'',
      department:'',
      company:'',
      headline:'',
      moreInfo:[] as any
    })
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSubmitData = () => {
  if ( edit === true) {
    setIsLoading(!isLoading)
    let data = contactCard[selectCard]
    if (contactData) {
      for (let key in contactData) {
        data[key] = contactData[key]
      }
    }
    if(contactData.profileImg.length && contactData.prefix.length &&contactData.firstName.length && contactData.lastName.length && contactData.middleName.length &&  contactData.suffix.length && contactData.accreditations.length && contactData.prefferredName.length && contactData.maidenName.length && contactData.pronouse.length && contactData.title.length && contactData.department.length && contactData.company.length && contactData.headline.length){
      if(filterData.length > 0){
        for (let index = 0; index < filterData.length; index++) {
          if(filterData[index].firstInput.length > 0 && filterData[index].subInput.length > 0){
            check.push(true) 
          }
        }
        if (check.length === filterData.length) {
          contactCard[selectCard] = data
          setOpen(false)
          setShowSubAlert(false)
          setEdit(false)
        } else{
          setShowSubAlert(true)
        }
      } else {
        contactCard[selectCard] = data
        setOpen(false)
        setEdit(false)
        setShowAlert(false)
        setContactdata({...contactData,
          profileImg:'',
          theme:1,
          prefix:'',
          firstName:'',
          middleName:'',
          lastName:'',
          suffix:'',
          accreditations:'',
          prefferredName:'',
          maidenName:'',
          pronouse:'',
          title:'',
          department:'',
          company:'',
          headline:'',
          moreInfo:[] as any
        })
      }
      setShowAlert(false)
      setContactdata({...contactData,
        profileImg:'',
        theme:1,
        prefix:'',
        firstName:'',
        middleName:'',
        lastName:'',
        suffix:'',
        accreditations:'',
        prefferredName:'',
        maidenName:'',
        pronouse:'',
        title:'',
        department:'',
        company:'',
        headline:'',
        moreInfo:[] as any
      })
    } else {
      setShowAlert(true)
    }
  } else {
    setIsLoading(!isLoading)
    if(contactData.profileImg.length && contactData.prefix.length &&contactData.firstName.length && contactData.lastName.length && contactData.middleName.length &&  contactData.suffix.length && contactData.accreditations.length && contactData.prefferredName.length && contactData.maidenName.length && contactData.pronouse.length && contactData.title.length && contactData.department.length && contactData.company.length && contactData.headline.length){
      if(filterData.length > 0){
        for (let index = 0; index < filterData.length; index++) {
          if(filterData[index].firstInput.length > 0 && filterData[index].subInput.length > 0 ){
            check.push(true)
          }
        }
        if (check.length === filterData.length) {
          contactCard.push(contactData)
          setOpen(false)
          setShowSubAlert(false)
          setContactdata({...contactData,
            profileImg:'',
            theme:1,
            prefix:'',
            firstName:'',
            middleName:'',
            lastName:'',
            suffix:'',
            accreditations:'',
            prefferredName:'',
            maidenName:'',
            pronouse:'',
            title:'',
            department:'',
            company:'',
            headline:'',
            moreInfo:[] as any
          })
        } else{
          setShowSubAlert(true)
        }
      } 
      else {
        contactCard.push(contactData)
        setOpen(false)
        setContactdata({...contactData,
          profileImg:'',
          theme:1,
          prefix:'',
          firstName:'',
          middleName:'',
          lastName:'',
          suffix:'',
          accreditations:'',
          prefferredName:'',
          maidenName:'',
          pronouse:'',
          title:'',
          department:'',
          company:'',
          headline:'',
          moreInfo:[] as any
        })
      }
      setShowAlert(false)
    } else {
      setShowAlert(true)
    }
  }
 
  }

  const handleRemove = (e:any) => {
  const remove = filterData.filter((item:any) => item.label !== e.label)
  setContactdata({...contactData,moreInfo:remove})
  setShowSubAlert(false)
  }

const handleAddMoreInput = (keyName:any,e:any,index:any) => {
  setAddMoreInfo({...addMoreInfo, [keyName] : e.target.value})
  if(keyName === 'mainInput'){
    filterData[index].firstInput = e.target.value
  } else if ( keyName === 'subInput' ){
    filterData[index].subInput =  e.target.value
  }
}
const handleContactEdit = (item:any,index:any) => {
  setOpen(true)
  setContactdata({...contactData,
    profileImg:item.profileImg,
    theme:item.theme,
    prefix:item.prefix,
    firstName:item.firstName,
    middleName:item.middleName,
    lastName:item.lastName,
    suffix:item.suffix,
    accreditations:item.accreditations,
    prefferredName:item.prefferredName,
    maidenName:item.maidenName,
    pronouse:item.pronouse,
    title:item.title,
    department:item.department,
    company:item.company,
    headline:item.headline,
    moreInfo:item.moreInfo
  })
  setEdit(true)
  setSelectCard(index)
  }
  
  const handleSearch = (e:any) => {
    setSearchData(e.target.value)
    const filterdItem = contactCard.filter((user:any)=> 
    user?.firstName?.toLowerCase().includes(searchData?.toLowerCase()))
    sewtShowSearchData(filterdItem)
  }

useEffect(() => {
  setIsLoading(!isLoading)
  for (let index = 0; index < filterData?.length; index++) {
  const element = filterData[index].label === 'Phone' ? OnlyNumbers(filterData[index].firstInput) : filterData[index].label === 'Email' ? validateEmail(filterData[index].firstInput) :validateUrl(filterData[index].firstInput)
  filterData[index].verfiyUrl = element
  if(element){
    setError(true)
  }else{
    setError(false)
  }
  }
},[addMoreInfo])

  return (
    <Box className="mainLoginBox">
      {!maxWidth890px && <SideBar commonTexts={texts.commonText}  openDrawer={openDrawer}/>}
    <Box className='layout' >
    <HeaderBar openAddCards={handleDrawerOpen} openDrawer={openDrawer} handleDrawer={handleDrawer}
              searchInput={searchData}
              commonText={texts.commonText}
              />

      <AddSearchBar 
      handleSearch={handleSearch}
      handleOpen={handleOpen}
      placeHolder='Search Contacts' 
      buttonName='Add'/>
      <ContactCards contactCard={contactCard} handleContactEdit={handleContactEdit} showSearchData={showSearchData} searchData={searchData}/>
      <AddContact 
      commonText={texts}
      handleClose={handleClose} 
      open={open} 
      showAlert={showAlert}
      contactData={contactData}
      handleSubmitData={handleSubmitData}
      handleContactData={handleContactData}
      handleAddMoreInfo={handleAddMoreInfo}
      handleRemove={handleRemove}
      filterData={filterData}
      handleAddMoreInput={handleAddMoreInput}
      showSubAlert={showSubAlert}
      addMoreInfo={addMoreInfo}
      error={error}
      />
    </Box>
    </Box>
  )
}

export default ContactPage