import React from "react";
import { TextField } from "@mui/material";
import "./inputField.css";
import { commonColors } from "../../../Assets/colors.ts";
interface fieldDetails {
    value?: any,
    onChange?: any,
    label?: any,
    type?:any
    onInput?:any,
    ref?:any
   
}

export default function InputField({ value, onChange, label,type,onInput,ref }: fieldDetails) {
    return (<TextField className="nameFieldBox"
        value={value}
        onChange={onChange}
        InputLabelProps={{ shrink: false }}
        onInput={onInput}
        label={label}
        size="small" 
        type={type}
        ref={ref}
        sx={{
            input: {
              fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
            },
            label: {
              fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
            }
          }}
    />)
}