import { Box, Button, Divider, Typography, Card, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { commonColors } from '../../Assets/colors.ts';
import commonTexts from '../../Assets/CommonTexts/texts.json'
import service1 from '../../services/service1.ts';
import { useParams } from 'react-router';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import links from '../../Components/dashboardComponent/addCardLayout/socialLinks.json';
import AddIcon from '@mui/icons-material/Add';
import "./cardPage.css";
import HeaderBar from '../../Components/commonComponents/headerBar/headerBar.tsx';
import SideBar from '../../Components/commonComponents/sideBar/sideBar.tsx';
import texts from "../../Assets/CommonTexts/texts.json";
import CardView from '../../Components/dashboardComponent/cardView/cardView.tsx';


function CardPage() {
  
   // Custom States
  
    const [card,setCard] = useState<any>(
      // This object is create just for example.
     {
      color: 6,
      imageUrl: require('../../Assets/Images/image/demo2.webp'),
      layout: 3,
      logoUrl: require('../../Assets/Images/Icons/apple.png'),
      fullName: 'Full name',
      role: 'Position and Role',
      company: 'Company',
      phoneNumber: '1234567890',
      emails: 'Email@gmail.com',
      instagram: '@instagram_id',
      twitter: '@twitter_id',
      linkedin: '@linkedin_id',
      youtube: '@youtube_id',
      facebook: '@facebook_id'
    })
    // this will get params passed in this page's url.
    const params = useParams()
    const maxWidth890px = useMediaQuery('(max-width:890px)')
    const [openDrawer, setOpenDrawer] = useState(false)
    const [addNewCard, setAddNewCard] = useState(false)

    // Custom Methods

    const getCardData = async () => {
      const response = await service1.getCards()
       // This will find same cardId matches with params Id, end will get that card details to show in card?.
      const cardData = response.find((card) => card?.cardId === params.id)
      console.log('response-->', response);
      setCard(cardData)
    }

    const handleDrawer = (value: any) => {
      setOpenDrawer(value)
      }

    const handleOpen = () => {
      return setAddNewCard(true);
    };

    const handleColor = (value :any, faded?: any) => {
      if (value === 1) {
        return faded ? commonColors.dynemicFadedLightPurple : commonColors.dynemicLightPurple;
      } else if (value === 2) {
        return faded ? commonColors.fadedMainColor :  commonColors.MainColor;
      } else if (value === 3) {
        return faded ? commonColors.dynamicFadedRed : commonColors.dynamicRed;
      } else if (value === 4) {
        return faded ? commonColors.dynamicFadedGreen : commonColors.dynamicGreen;
      } else if (value === 5) {
        return faded ? commonColors.dynamicFadedPurple : commonColors.dynamicPurple;
      } else if (value === 6) {
        return faded ? commonColors.dynamicFadedOrange : commonColors.dynamicOrange;
      } else if (value === 7) {
        return faded ? commonColors.dynamicFadedYellow : commonColors.dynamicYellow;
      } else {
        return faded ? commonColors.dynamicFadedBlue : commonColors.dynamicBlue;
      }
    };

    // Life Cycle Metod

    useEffect(() =>{
      // Uncomment this method whenever scanner method will run.
      // To get all cards data
      // getCardData()
      console.log('card--->', card)
    },[])

    // UI Implamentation

  return (
    <Box className="cardPagemainBox">
        {!maxWidth890px && 
           <SideBar  
           commonTexts={texts.commonText}
           openDrawer={openDrawer}
           />}
            <Box className='cardPagelayout'>
              <HeaderBar  
              openAddCards={handleOpen}
              openDrawer={openDrawer} 
              handleDrawer={handleDrawer}
              commonText={texts.commonText}
              />
              <Box className="cardLayoutShowBox">
        <Box className="cardPageBox">
        <Box className="cardPageLayoutBox">
          <div
            className="cardLayoutBox"
            style={{
              borderColor: handleColor(card?.color),
            }}
          >
            <div
              style={{
                backgroundColor: card?.imageUrl.length < 1 ? handleColor(card?.color): undefined,
                width: "100%",
                overflow: "hidden",
                height: "180px",
                minHeight: "130px",
                borderRadius: `${ card?.layout === 1 ? '0 0 35% 35%' : card?.layout === 2 ? '0 0 0 0' : '0 0 0 0' }`,
                borderBottomRightRadius: `${card?.layout === 1 ? '35%' :card?.layout === 3 ? '150px' : '0'}`,
                borderBottomLeftRadius:`${card?.layout === 1 ? '35%' : '0'}`,
                // borderTopLeftRadius: "15px",
                // borderTopRightRadius: "15px",
                // borderBottomRightRadius:
                //   card?.layout === 3
                //     ? "20vh"
                //     : card?.layout === 1
                //     ? "35%"
                //     : "0%",
                // borderBottomLeftRadius:
                //   card?.layout === 3
                //     ? "0%"
                //     : card?.layout === 1
                //     ? "35%"
                //     : "0%",
                backgroundImage: `url(${card?.imageUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              {card?.logoUrl && (
                <div className="cardPageLayoutLogo">
                  <img src={card?.logoUrl} className="cardPageLogo" />
                </div>
              )}
            </div>
            <div className="cardPageInfoBox">
              <Typography className="cardPageInfoName">
                {card?.fullName}
              </Typography>
            { card?.role ||  card?.company ?<Typography className="cardPageInfocmpany">
                {card?.role} • {card?.company}
              </Typography> : ""}
            </div>
            <div className="cardPageSocialInfoBox">
              {links.socialLinks.map((links, index) =>
                card[links.name]?.length > 0 ? (
                  <div className="cardPageSocialField" key={links.name}>
                    <img
                      src={require(`../../Assets/Images/Icons/${links.img}`)}
                      alt={links.name}
                      className="cardPageSocialIcons"
                      style={{ backgroundColor: handleColor(card?.color)}}
                    />
                    <Typography className="cardPageSocialInfoText">
                      {card[links.name]?.length ? card[links.name] : ''}
                    </Typography>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Box>
        <div className='cratecardBtnBox'>
            <Button disableRipple className='createCardBtn'>
            {texts.commonText?.createYourOwnCard}
              <AddIcon/>
            </Button>
          </div>
        {/* <div className="customCard">
                <div
                  style={{
                    background: card?.color === 5 ? commonColors.dynamicPurple : card?.color == 4 ? commonColors.dynamicBlue : card?.color == 3 ? commonColors.dynamicGreen : card?.color == 2 ? commonColors.dynamicOrange : commonColors.MainColor,
                    width: "100%",
                    height:card?.layout === 3 ? "140px" : card?.layout === 2 ? "120px" : "105px",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    borderBottomRightRadius:card?.layout === 3 ? "80%" : card?.layout === 2 ? "50%" : "0%",
                    borderBottomLeftRadius:card?.layout === 3 ? "0%" : card?.layout === 2 ? "50%" : "0%",
                  }}
                >
                  {card?.logoUrl && (
                    <div className="drwerCardLogoBox" >
                      <img
                        src={card?.logoUrl}
                        className="drwerCardLogo"
                      />
                    </div>
                  )}
                </div>
                {card?.imageUrl && (
                  <div>
                    <img
                      src={card?.imageUrl}
                      className="drwerCardImgBox"
                    />
                  </div>
                )}
                <div style={{ padding: "10px" }}>
                  <Typography className="textOverflow">{card?.pronouns} {card?.firstName} {card?.lastName} </Typography>
                  <Divider />
                  <Typography className="textOverflow">{card?.title}</Typography>
                  <Typography className="textOverflow"> {card?.company}</Typography>
                  <Divider />
                  <Typography className="textOverflow">{card?.phoneNumber}</Typography>
                  <Typography className="textOverflow">{card?.emails}</Typography> 
                  <Divider />
                  <Typography className="textOverflow">{card?.address}</Typography>
                  <Divider />
                  <Typography className="textOverflow">{card?.department}</Typography>
                  <Typography className="textOverflow">{card?.headline}</Typography>
                  <Divider />
                 {card?.linkedin?.length > 0 && <div className="displayFlex">
                    <LinkedInIcon />
                  <Typography className="textOverflow">{card?.linkedin}</Typography>
                  </div>}
                 {card?.instagram?.length > 0 &&  <div className="displayFlex">
                    <InstagramIcon />
                  <Typography className="textOverflow">{card?.instagram}</Typography>
                  </div>}
                  {card?.twitter?.length > 0 &&  <div className="displayFlex">
                    <XIcon />
                  <Typography className="textOverflow">{card?.twitter}</Typography>
                  </div>}
                 {card?.website?.length > 0 &&  <div className="displayFlex">
                    <LanguageIcon />
                  <Typography className="textOverflow">{card?.website}</Typography>
                  </div>}
                </div>
              </div> */}
            {/* <Box
              sx={{
                background:
                card?.color === 5 ? commonColors.dynamicPurple : card?.color == 4 ? commonColors.dynamicBlue : card?.color == 3 ? commonColors.dynamicGreen : card?.color == 2 ? commonColors.dynamicOrange : commonColors.MainColor,
                width: "100%",
                height: card?.layout === 2 ? "120px" : "100px",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                borderBottomRightRadius:card?.layout === 3 ? "80%" : card?.layout === 2 ? "50%" : "0%",
                borderBottomLeftRadius:card?.layout === 3 ? "0%" : card?.layout === 2 ? "50%" : "0%",
              }}
            >
              <div  style={{display:"flex", justifyContent:"space-between" , padding:"10px"}}>
              <a></a>  <img src={card?.logoUrl}className="custionCardLogo"/>
              </div>
            </Box>
            <div className='customImgBtnBox'>
            <img
              className="customCardImg"
                src={card?.imageUrl}
              />
            </div>
            <div style={{ padding: "10px" }}>
              <Typography>
                {card?.prifix} {card?.firstName} {card?.middleName} {card?.lastName}
              </Typography>
              <Typography>{card?.suffix}</Typography>
              <Divider />
              <Typography>
                {card?.maidenName && <>({card?.maidenName})</>} {card?.accreditation}
              </Typography>
              <Divider />
              <Typography>{card?.title}</Typography>
              <Typography> {card?.department}</Typography>
              <Typography> {card?.company}</Typography>
              <Typography> {card?.headline}</Typography>
              <Divider />
              {card?.preFerredName && (
                <Typography>
                  {commonTexts?.commonText.goesBy}:{card?.preFerredName}
                  {card?.pronouns && <>({card?.pronouns})</>}
                </Typography>
              )}
            </div> */}
          </Box>
          </Box>
          </Box>
    </Box>
  )
}

export default CardPage