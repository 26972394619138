import React from "react";
import { Box, Typography, Divider, useMediaQuery } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface FirstStepLayoutDetails {
    layouts : any,
    cardData: any,
    handleColor: any,
    handleCardLayout: any,
    handleLocalImage: any,
    theme: any,
    images: any,
    profileLayout: any,
    handleLogo: any,
    handleProfileImage: any
    logoLayout: any
    handleImage:any
    commonTexts:any
    // selectedImage:any
    windowHeight:any
}

export default function FirstStepLayout ({windowHeight, layouts, cardData, handleColor,handleCardLayout,handleLocalImage,theme,images,profileLayout,handleLogo,handleProfileImage,logoLayout, handleImage, commonTexts} : FirstStepLayoutDetails){

  // const tabletHeight = useMediaQuery('(max-height:850px)')
  const mobileView = useMediaQuery('(max-width:450px)')
  
  return (
  <Box className="firstLayoutStepBox">
              <div className="bgPatternMainBox">
                <Typography className="bgPatternHeading">
                {commonTexts?.bgPattern}
                </Typography>
                <div className="bgPatternBoxes">
                  {layouts.map((it: any, index: any) => (
                    <span
                      key={index}
                      style={{
                        border:
                          cardData.layout === index + 1 ? `1px solid ${handleColor(cardData.color)}` : "",
                        borderRadius: "5px",
                        padding: mobileView? "7px" : "10px",
                        backgroundColor: it.design === cardData.layout ? handleColor(cardData.color, true) : '',
                      }}
                    >
                      <div
                        key={it.design}
                        onClick={() => handleCardLayout("layout", it.design)}
                        className="bgPatternLayouts"
                      >
                        <div
                          style={{
                            background: handleColor(cardData.color),
                            width:"100%",
                            height: mobileView? "35px" : "55px",
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                            borderBottomRightRadius:
                              it.design === 3
                                ? "15vh"
                                : it.design === 1
                                ? "35%"
                                : "0%",
                            borderBottomLeftRadius:
                              it.design === 3
                                ? "0%"
                                : it.design === 1
                                ? "35%"
                                : "0%",
                          }}
                        ></div>
                      </div>
                      <Typography className="patternLayoutLabel">
                        {it.label}
                      </Typography>
                    </span>
                  ))}
                </div>
              </div>
              <Divider className="layoutDivider" />
              <div className="bgPatternMainBox">
                <Typography className="bgPatternHeading">
                 {commonTexts?.virtualBg}
                </Typography>
                <span className="bgPatternBoxes">
                  <div className="addvirtualImg"
                   style={{ backgroundColor: handleColor(cardData.color, true),
                    border: `1px solid ${handleColor(cardData.color)}`}}
                    // onClick={handleLocalImage}
                  >
                    {/* <input
                      id="localImage"
                      onChange={(e) => handleLocalImage(e)}
                      type="file"
                      hidden
                    /> */}
                    <label htmlFor="localImage">
                      <div className="virtualImgBtnBox"
                       style={{ color: handleColor(cardData.color)}}
                      >
                        <CancelOutlinedIcon />
                      </div>
                    </label>
                  </div>
                  {images.map((item: any, i: any) => (
                    <a 
                    className="addVirtualCardimgBg"
                    style={{
                      backgroundColor: cardData.virtualImage.includes(item.img) ? handleColor(cardData.color, true) : '',
                      border: cardData.virtualImage.includes(item.img) ? `1px solid ${handleColor(cardData.color)}`: '',
                    }}
                    //  onClick={() => handleImage(item.img)}
                      key={i}>
                      <img className="virtualImg" src={item.img} alt="" />
                    </a>
                  ))}
                </span>
              </div>
              <Divider className="layoutDivider" />
              <div className="bgPatternMainBox">
                <Typography className="bgPatternHeading">{commonTexts?.color}</Typography>
                <span className="bgPatternBoxes">
                  {theme.map((it: any, index: any) => (
                    <span
                      className={`colorBox ${
                        cardData.color === index + 1 ? "colorBorder" : ""
                      }`}
                      key={index}
                    >
                      <div
                        onClick={() => handleCardLayout("color", it.colour)}
                        className={
                          cardData.color === index + 1
                            ? "selectedFilledColor"
                            : "filledColors"
                        }
                        style={{
                          backgroundColor: handleColor(it.colour),
                        }}
                      ></div>
                    </span>
                  ))}
                </span>
              </div>
              <Divider className="layoutDivider" />
              <div className="uploadImgLogobox">
                <div className="logoImageCommonbox">
                  <Typography className="bgPatternHeading">
                  {commonTexts?.uploadProfile}
                  </Typography>
                  <span className="uploadImgMainBox">
                    {profileLayout.map((it) => (
                      <label key={it.profile} htmlFor="profileImage">
                        <div key={it.profile} className="profilePhotoUploadBox">
                          {cardData.imageUrl ? (
                            <img
                              className="profilePhotoLayouts"
                              src={cardData.imageUrl}
                            />
                          ) : (
                            <>
                              <img
                                src={require("../../../Assets/Images/Icons/uploadCloud.png")}
                                alt=""
                                className="uploadCloudImg"
                              />
                              <Typography className="imgLabel">
                               {commonTexts?.dropImg}
                              </Typography>
                            </>
                          )}
                        </div>
                      </label>
                    ))}
                    <input
                      hidden
                      id="profileImage"
                      type="file"
                      onChange={(e) => handleProfileImage(e)}
                    />
                  </span>
                </div>
                <div className="logoImageCommonbox">
                  <Typography className="bgPatternHeading">
                  {commonTexts?.uploadLogo}
                  </Typography>
                  <span className="uploadImgMainBox">
                    {logoLayout.map((it) => (
                      <label key={it.logo} htmlFor="file-input">
                        <div key={it.logo} className="profilePhotoUploadBox">
                          {cardData.logoUrl ? (
                            <img
                              className="profilePhotoLayouts"
                              src={cardData.logoUrl}
                            />
                          ) : (
                            <>
                              <img
                                src={require("../../../Assets/Images/Icons/uploadCloud.png")}
                                alt=""
                                className="uploadCloudImg"
                              />
                              <Typography className="imgLabel">
                              {commonTexts?.dropLogo}
                              </Typography>
                            </>
                          )}
                        </div>
                      </label>
                    ))}
                    <input
                      hidden
                      id="file-input"
                      type="file"
                      onChange={(e) => handleLogo(e)}
                      style={{ width: "100px" }}
                    />
                  </span>
                </div>
              </div>
            </Box>
            )
}