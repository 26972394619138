import React from "react";
import { Box, Button, Dialog, Modal, Typography } from "@mui/material";
import "./confirmModal.css";

interface confirmModalDetails {
    commonTexts: any
    open: any
    onClose: any
    confirmClick: any
}
export default function ConfirmModal ({open, onClose, confirmClick, commonTexts}: confirmModalDetails) {

    return (
        <Dialog
        open={open}
        onClose={onClose}
        >
            <Box className="confirmModalBox">
                <Typography className="modalHeadingText">
                  {commonTexts?.areYouSure}
                </Typography>
                <Box className="submitBtnsBox">
                <Button onClick={confirmClick} className="confirmBtn">
                {commonTexts?.yes}  
                </Button>
                <Button onClick={onClose} className="cancelBtn">
                {commonTexts?.no}  
                </Button>
                </Box>
            </Box>
        </Dialog>
    )
}