import React, { useEffect, useRef, useState } from "react";
import "./qrBox.css";
import { Box, Button, Typography } from "@mui/material";
import service1 from "../../services/service1.ts";
// import img from "../../Assets/Images/image/codeQr.png"
// import "./qrCodePage.css";
import { RWebShare } from "react-web-share";
import KeyboardOptionKeyIcon from '@mui/icons-material/KeyboardOptionKey';
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import IosShareIcon from '@mui/icons-material/IosShare';

interface props{
    url:any,
    qrCode?:any
    commonTexts:any
}
export default function QrBox({url, qrCode, commonTexts}:props) {
const [qrCodeLink, setQrcodeLink] = useState('')

    // const imgss = `data:image/png;base64:${qrCode}`
    
    // used qrCodeRef to get element which contain qrcode.
    const qrCodeRef = useRef(null);
    
    const downloadQRCode = () => {
        // This method will convert element into image(png) file, and then we will download that image as qrcode image.
        if (qrCodeRef.current !== null) {
      htmlToImage
        .toPng(qrCodeRef.current)
        .then(function (dataUrl) {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "qr-code.png";
// this will store qrCode image share link.
          setQrcodeLink(link.href)
// if you uncomment this(link.click()), than it will download qrcode image whenever this(downloadQRCode) method will call.
          // link.click();
        })
        .catch(function (error) {
          console.error("Error generating QR code:", error);
        });
    }
    };
    useEffect(() => {
      // to generate qrCode image share link.
      downloadQRCode()
    },[])

    return (
      <Box className="qrBox">
        <div className="qrLayoutBox">
          <div ref={qrCodeRef} className="qrCode">
            <div className="qrCurvyBorderBox">
              {/* This url is card's url which card you wan't to show after scanning qrcode. */}
    <QRCode value={url} className="qr"/>
    </div>
          </div>
    <Typography className="qrScanText">{commonTexts?.scanYourCode}</Typography>
          <div className="qrShareBtnBox">
            {/* This button will donload qrcode image. */}
  {/* <Button className='qrShareBtn' onClick={downloadQRCode}>Download QR Code</Button> */}
  {/* This RwebShare will navigate and copy global link of qrcode image. */}
  <RWebShare data={{url: qrCodeLink}}>
    <Button className='qrShareBtn'><IosShareIcon fontSize="small"/> {commonTexts?.share}</Button>
  </RWebShare>
          </div>
        </div>
      </Box>
    )
}