
import Api from './ApiLogin.ts';
import { SIGNIN, } from './ApiName.ts';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = await cookies.get('token')

const config = {
    headers: {
        accept: 'application/json',
    }
};

export default {
    async login(data: any) {
        var response = await Api().post(SIGNIN,data, config);
        // console.log("Login Response----", response)
        return response.data;
    },
}