import React, { useEffect, useState, useRef } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import "../dashBoard/dashBoardPage.css";
import texts from "../../Assets/CommonTexts/texts.json"
import DetailsBox from "../../Components/dashboardComponent/detailsBox.tsx";
import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
import QrBox from "../../Components/qrCodeComponent/qrBox.tsx";
import service1 from "../../services/service1.ts";
import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";


export default function QrCodePage() {
    // Custome states
    const [url, setUrl] = useState("http://localhost:3000/card/07ac3aa0-1001-447c-bd0c-42e0cbc60189")
    const [openDrawer, setOpenDrawer] = useState(false)
    const [searchData, setSearchData] = useState('')
    const [addNewCard, setAddNewCard] = useState(false);

    // const [qrIsVisible, setQrIsVisible] = useState(false);
    // const [qrCode,setQrCode] = useState()

    // const getQRData = async() => {
    //     const response = await service1.getQRCode();
    //     console.log("sldlsdlsd,ls-----",response);
    //     setQrCode(response)
    // }

        // useEffect(() => {
    //     getQRData();
    // },[])
    const maxWidth890px = useMediaQuery('(max-width:890px)')

    // Custome Methodes
    const handleOpen = () => {
        return setAddNewCard(true);
      };
    const handleDrawer = (value: any) => {
        setOpenDrawer(value)
        }
        
    // UI Implamentation
    return (
        <Box className="mainLoginBox">
            {!maxWidth890px && <SideBar commonTexts={texts.commonText}  openDrawer={openDrawer}/>}
            <Box className='layout'>
            <HeaderBar  openAddCards={handleOpen} openDrawer={openDrawer} handleDrawer={handleDrawer}
              searchInput={searchData}
              commonText={texts.commonText}
              />
            <QrBox url={url} commonTexts={texts.commonText}/>
            </Box>
        </Box>
    )
}