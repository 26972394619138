import React from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";


interface ThirdStepLayoutDetails {
  displayTexts: any;
  cardData: any;
  handleCardDisplayLayout: any;
  handleCardLayout: any;
  validateEmail: any;
  socialDetails: any;
  handleExpandSocialDetails: any;
  commonTexts: any;
  showAlert: any;
}

export default function ThirdStepLayout({
  displayTexts,
  cardData,
  showAlert,
  handleCardDisplayLayout,
  handleCardLayout,
  socialDetails,
  handleExpandSocialDetails,
  commonTexts,
}: ThirdStepLayoutDetails) {
  
  // Custome State
  const socialMedia = [
    {
      expandKey: "instagramExpand",
      headText: "instgram",
      carddataKey: "instagram",
      errorText: "enterInstagramId",
      displayErrorText: "enterInstagramText",
      img: "instaColored.png",
    },
    {
      expandKey: "twitterExpand",
      headText: "twitterX",
      carddataKey: "twitter",
      errorText: "enterTwitterId",
      displayErrorText: "enterTwitterText",
      img: "twitterColored.png",
    },
    {
      expandKey: "linkedinExpand",
      headText: "linkedin",
      carddataKey: "linkedin",
      errorText: "enterLinkedInId",
      displayErrorText: "enterLinkedInText",
      img: "linkedinColored.png",
    },
    {
      expandKey: "youtubeExpand",
      headText: "youtube",
      carddataKey: "youtube",
      errorText: "enterYoutubeId",
      displayErrorText: "enterYoutubeText",
      img: "youtubeColored.png",
    },
    {
      expandKey: "facebookExpand",
      headText: "facebook",
      carddataKey: "facebook",
      errorText: "enterFaceBookText",
      displayErrorText: "enterFaceBookText",
      img: "faceBookColored.png",
    },
  ];

  return (
    <Box className="yourDetailsMainbox">
      <Box className="detailsFilleBox1">
        {socialMedia.map((item) => (
          <Accordion
            disableGutters
            key={item.expandKey}
            expanded={socialDetails[item?.expandKey]}
            className="socialMediaMainBox"
            onChange={handleExpandSocialDetails(item?.expandKey)}
          >
            <AccordionSummary className="socialMediaheaderBox">
              <Checkbox checked={socialDetails[item?.expandKey]} size="small" />
              <img
                className="scoialImg"
                src={require(`../../../Assets/Images/Icons/${item?.img}`)}
                alt=""
              />
              <span>
                <Typography className="socialMediaheadrTitle">
                  {commonTexts[item?.headText]}
                </Typography>
                <Typography className="socialMediaheadrTitle1">
                  {commonTexts?.social}
                </Typography>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="detailsLabelInputBox1">
                <Typography className="headingFont">
                  {commonTexts?.yourId}
                </Typography>
                {showAlert && cardData[item?.carddataKey]?.length == 0 && (
                  <Typography className="alertText">
                    {commonTexts[item?.errorText + ' ']}
                  </Typography>
                )}
                <TextField
                  size="small"
                  value={cardData[item?.carddataKey]}
                  onChange={(e) => handleCardLayout(item?.carddataKey, e)}
                  className="detailsInputField"
                  placeholder={commonTexts?.userId}
                  InputProps={{
                    startAdornment: (
                      <div className="socialInputadornment">
                        <AlternateEmailIcon fontSize="inherit" />
                      </div>
                    ),
                  }}
                  sx={{
                    input: {
                      marginLeft: "5px",
                      fontFamily:
                        "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
                    },
                  }}
                />
              </div>
              <div className="detailsLabelInputBox1">
                <Typography className="headingFont">
                  {commonTexts?.displayText}
                </Typography>
                {showAlert && displayTexts[item?.carddataKey]?.length === 0 && (
                  <Typography className="alertText">
                    {commonTexts[item?.displayErrorText]}{" "}
                  </Typography>
                )}
                <TextField
                  size="small"
                  value={displayTexts[item?.carddataKey]}
                  onChange={(e) =>
                    handleCardDisplayLayout(item?.carddataKey, e)
                  }
                  className="detailsInputField"
                  placeholder={commonTexts?.enterText}
                  sx={{
                    input: {
                      fontFamily:
                        "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;",
                    },
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}
