import { Button, TextField } from '@mui/material'
import React from 'react'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { commonColors } from "../../../Assets/colors.ts";

interface props {
    handleSearch?:any
    handleOpen?:any
    buttonName?:any
    placeHolder?:any
}

function AddSearchBar({handleSearch,handleOpen,buttonName,placeHolder}: props) {
  return (
    <div  style={{width:"100%"}}>
         <span className="addSearchBtn">
        <TextField
          style={{ width: "98%" }}
          size="small"
          onChange={(e) => handleSearch(e)}
          placeholder={placeHolder}
          sx={{
            border: "none",
            "& fieldset": { border: "none" },
            borderRadius: "10px",
            boxShadow: `0px 0px 10px 2px ${commonColors.shadowGaryColor} `,
          }}
        />
        <Button onClick={handleOpen} className="addBtn">
          <AddCircleOutlineRoundedIcon sx={{ marginRight: "8px" }} />
          {buttonName}
        </Button>
      </span>
    </div>
  )
}

export default AddSearchBar