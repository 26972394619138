import React from 'react'
import WhiteBox from '../commonComponents/whiteBox/whiteBox.tsx'
import { Box, Button, Typography } from '@mui/material'
import './publicDetails.css'


function PublicDetails() {
  return (
    <Box className='detailsMainBox' >
        <Box className='whiteDetailBox' >
            <div className='detailSubBox'>
            <Typography className='detailTitle'>
                Name: <Typography className='detailData'>John John</Typography> 
            </Typography>
            <Typography className='detailTitle2'>
               email:<Typography className='detailData'>Joho123@asd.com</Typography>
            </Typography >
            <Typography className='detailTitle'>
               phone number:<Typography className='detailData'>1234567890</Typography>
            </Typography>
            <Typography className='detailTitle2'>
            address:<Typography className='detailData'>Vadodara,Gujarat</Typography>
            </Typography>
            <Typography className='detailTitle'>
            business:<Typography className='detailData'>Coca-Cola</Typography>
            </Typography>
            <Typography className='detailTitle2'>
            birthday:<Typography className='detailData'>1-jan-1978</Typography>
            </Typography>
            <Typography className='detailTitle'>
            social profile links:<Typography className='detailData'>www.google.com</Typography>
            </Typography>
            <Typography className='detailTitle'>
            business url:<Typography className='detailData'>www.google.com</Typography>
            </Typography>
            </div>
            <span className='addToBtnBox'>
            <Button className='addToContact'>Add to Contact</Button>
            </span>
        </Box>
    </Box>
  )
}

export default PublicDetails