import React, { useRef, useState } from "react";
import WhiteBox from "../commonComponents/whiteBox/whiteBox.tsx";
import { Box, Button, IconButton, Typography } from "@mui/material";
import "./scannerBox.css"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from "react-router";

interface scannerDetails {
    texts: any
}

export default function ScannerBox({ texts }: scannerDetails) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [image, setImage] = useState<any>()
  const [cardId, setCardId] = useState('07ac3aa0-1001-447c-bd0c-42e0cbc60189')
  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  const profileImgRef = useRef<any>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleScanCard = () => {
      // After scanning qrcode this navigation will call and cardId will be passed in to this url and it will navigate to card details page for perticular card.
      navigate(`/card/${cardId}`)
    }
 
    const handleImage = (event: any) => {
      setImage(URL.createObjectURL(event.target.files[0]))
      setAnchorEl(null);
    };

    const handleRemoveImg = () => {
      setImage('')
    }
     
    return (
        <WhiteBox
            title={texts?.allowCamera}
            titleColor='main'
            className='page2NextBtn'
            onClick={handleScanCard}
            btnTitle={texts?.scanCard}
        >
            <Box className="ScannerBox">
              {
                image ?
                <Box className='imageMainBox'>
                <img src={image}  className="scannerImage"/>
                <IconButton disableRipple onClick={handleRemoveImg} className="scannerImageCloseIconBtn"><CloseRoundedIcon className="closeIcon"/></IconButton>
                </Box>:
                <>
                 <Button onClick={handleClick} disableRipple className="cameraBtn">
                    <Typography className="cameraTitle">{texts?.camera}</Typography>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem 
                    onClick={handleClose}>{texts?.camera}</MenuItem>
                  <MenuItem 
                    className="gallaryOption"
                    onClick={() => profileImgRef.current?.click()} 
                     >
                    <input hidden  ref={profileImgRef} onChange={(event: any) => handleImage(event)} type="file"/>
                      {texts?.gallery}
                  </MenuItem>
                </Menu>
                </>
              }
            </Box>
        </WhiteBox>
    )
}