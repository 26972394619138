import React, { useRef, useState } from "react";
import {
  Typography,
  Card,
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface viewCardDetails {
tabletMobileView: any;
preview: any;
handlePreview: any;
handleColor: any;
cardData: any;
countryCode: any;
displayTexts: any;
commonTexts: any;
links: any;
}

export default function CardView  ({tabletMobileView, preview, handlePreview, handleColor, cardData, countryCode, displayTexts, commonTexts, links} :viewCardDetails ) {
    return (
      <Card className={ tabletMobileView && !preview ? "mainCardLayoutbox1" : "mainCardLayoutbox"}>
          {tabletMobileView && <div onClick={handlePreview} className="cardPreviewBox">
            <Typography className="cardPreviewText">{commonTexts?.preview}</Typography>
            {preview ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </div>}
          { !tabletMobileView || preview ? <div
            className="cardLayoutBox"
            style={{
              borderColor: handleColor(cardData.color),
            }}
          >
            <div
              style={{
                backgroundColor: cardData.imageUrl.length < 1 ? handleColor(cardData.color): undefined,
                width: "100%",
                overflow: "hidden",
                height: "190px",
                minHeight: "190px",
                borderRadius: `${ cardData.layout === 1 ? '0 0 35% 35%' : cardData.layout === 2 ? '0 0 0 0' : '0 0 0 0' }`,
                borderBottomRightRadius: `${cardData.layout === 1 ? '35%' :cardData.layout === 3 ? '150px' : '0'}`,
                borderBottomLeftRadius:`${cardData.layout === 1 ? '35%' : '0'}`,
                // borderTopLeftRadius: "15px",
                // borderTopRightRadius: "15px",
                // borderBottomRightRadius:
                //   cardData.layout === 3
                //     ? "15vh"
                //     : cardData.layout === 1
                //     ? "35%"
                //     : "0%",
                // borderBottomLeftRadius:
                //   cardData.layout === 
                //     ? "0%"
                //     : cardData.layout === 1
                //     ? "35%"
                //     : "0%",
                backgroundImage: `url(${cardData.imageUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
    
              }}
            >
              {cardData.logoUrl && (
                <div className="cardLayoutLogo">
                  <img src={cardData.logoUrl} className="cardLogo" />
                </div>
              )}
            </div>
            <div className="cardInfoBox">
              <Typography className="cardInfoName">
                {cardData.fullName}
              </Typography>
            { cardData.role ||  cardData.company ?<Typography className="cardInfocmpany">
                {cardData.role} • {cardData.company}
              </Typography>: ""}
            </div>
            <div className="cardSocialInfoBox">
              {links.socialLinks.map((links, index) =>
                (links.name === "phoneNumber" || links.name === "emails") &&
                cardData[links?.name]?.length > 0 ? (
                  <div className="cardSocialField" key={links.name}>
                    <img
                      src={require(`../../../Assets/Images/Icons/${links.img}`)}
                      alt={links.name}
                      className="cardSocialIcons"
                      style={{ backgroundColor: handleColor(cardData?.color)}}

                    />
                    <Typography className="cardSocialInfoText">
                      {links.name === "phoneNumber" ? countryCode?.phone + ' ' : ""}
                      {cardData[links.name]}
                    </Typography>
                  </div>
                ) : links.name !== "emails" &&
                  links.name !== "phoneNumber" &&
                  displayTexts[links?.name]?.length > 0 ? (
                  <div className="cardSocialField">
                    <img
                      src={require(`../../../Assets/Images/Icons/${links.img}`)}
                      alt={links.name}
                      className="cardSocialIcons"
                      style={{ backgroundColor: handleColor(cardData?.color)}}
                    />
                    <Typography className="cardSocialInfoText">
                       {displayTexts[links.name]}
                    </Typography>
                  </div>
                ) : null
              )}
            </div>
          </div> : null}
      </Card>
    )
                  }